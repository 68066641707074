import { Component } from 'react'
import { connect } from 'react-redux'
import { ClipLoader } from "react-spinners"

import { SummaryItem } from './SummaryItem'
import Card, { SLRBaseProps } from './Card'
import Text from '../common/styled/Text'
import { TotalDivider } from './TotalDivider'
import { Box } from '@mui/material'
import { calculateNeedsPortfolioInTime, needsPortfolioModelUpdate, needsImmunizationPeriodUpdate, wantsPortfolioModelUpdate, wishesPortfolioModelUpdate } from '../../actions/standardLivingRisk'
import { loadModelOptions, loadConfigurationOptions } from '../../actions/portfolioDesigner'
import Chart from '../common/Chart'
import { LooseObject, SelectOption } from '../../common/types'
import { Calculation, FundingType, MontecarloResultSet } from '../../actions/types/standardLivingRisk'
import { Select } from '../common/styled/Select'
import { ReduxState } from '../../reducers'
import { ModelItemOption } from '../../actions/types/portfolioDesigner'
import { addError } from '../../actions/notifications'
import Button from '../common/Button'
import { WithClientParameterProps, withClientParameter } from '../common/withClientParameter'
import { formatNumberAsPercentage } from '../../common/utils'
import { exportDataToExcel } from '../../common/excel'
import { onSelectedKeyChanged } from '../../actions/session'

const FIXED_MONEY_GUIDE_INV_PORT_TODAY = 5000000
interface SLRProps extends SLRBaseProps, WithClientParameterProps {
    investmentPortfolioToday: number
    slr: string
    hideHeader: boolean
    hideFooter: boolean
    needsInTimeChart: LooseObject
    wantsInTimeChart: LooseObject
    wishesInTimeChart: LooseObject
    calculatingNeeds: boolean
    needsPortfolio: number
    wantsPortfolio: number
    wishesPortfolio: number
    yearsInFinancialGoal: number;
    yearsToFinancialGoal: number;
    needsWentNegative: boolean
    needsImmunizationPeriod: number;
    needsPortfolioModel: ModelItemOption | null
    wantsPortfolioModel: ModelItemOption | null
    wishesPortfolioModel: ModelItemOption | null
    portfolioModels: SelectOption[]
    financialPlanNeedsModels: SelectOption[]
    financialPlanSource: string
    montecarloResults?: MontecarloResultSet
    bands: any[]
    wantsWishes: any[]
    selectedKey: string
    onSelectedKeyChanged: (key: string) => void
    needsImmunizationPeriodUpdate: (option: SelectOption) => void
    needsPortfolioModelUpdate: (option: ModelItemOption) => void
    wantsPortfolioModelUpdate: (option: ModelItemOption) => void
    wishesPortfolioModelUpdate: (option: ModelItemOption) => void
    loadModelOptions: typeof loadModelOptions,
    loadConfigurationOptions: typeof loadConfigurationOptions,
    calculateNeedsPortfolioInTime: () => void
    addError: typeof addError
}

interface SLRState {
    selectedChartOption: SelectOption | null
}

export const CHART_OPTIONS = [{ label: 'Needs Portfolio', value: FundingType.NEED },{ label: 'Wants Portfolio', value: FundingType.WANT }, { label: 'Wishes Portfolio', value: FundingType.WISH }]

class SLR extends Component <SLRProps, SLRState> {

    constructor(props: SLRProps) {
        super(props)
        this.props.calculateNeedsPortfolioInTime()
        this.state = {
            selectedChartOption: CHART_OPTIONS[0],
        }
    }
    
    componentDidMount(): void {
        this.props.loadModelOptions();
        this.props.loadConfigurationOptions();
    }

    componentDidUpdate(prevProps: Readonly<SLRProps>, prevState: Readonly<SLRState>, snapshot?: any): void {
        if (prevProps.selectedKey !== this.props.selectedKey && prevProps.selectedKey === 'savingsGoals') {
            this.props.calculateNeedsPortfolioInTime()
        }
    }

    setSelectedChartOption = (selectedChartOption: SelectOption) => {
        this.setState({ selectedChartOption })
    }

    setSelectedNeedsPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })

            return
        }
        this.props.needsPortfolioModelUpdate(selectedOption)
    }

    setSelectedWantsPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })
            
            return
        }
        this.props.wantsPortfolioModelUpdate(selectedOption)
    }

    setSelectedWishesPortfolioModel = (selectedOption: ModelItemOption) => {
        if (!selectedOption.item.discountRate) {
            this.props.addError('Missing Assumptions', 'Please set an expected return for this model under the Financial Plan Assumptions page.', { duration: 5000 })
            
            return
        }
        this.props.wishesPortfolioModelUpdate(selectedOption)
    }


    mapCalculationToExcel = (calculations: Calculation[]) => {
        return calculations.map(({ start, inflows, outflows, returnRate, end }, year) => ({
            'Year': year,
            'Starting Balance': start,
            'Inflow': inflows,
            'Outflow': outflows,
            'Return': returnRate > 1 ? returnRate - 1 : returnRate,
            'Ending Balance': end
        }));
    }

    saveToExcel = () => {
        const { selectedChartOption } = this.state;
        if (!selectedChartOption) return;
        let calculations: Calculation[] = []
        if (selectedChartOption.value === FundingType.NEED) {
            calculations = this.props.bands?.find(({ name }) => name.includes('Needs'))?.calculations || []
        }
        if (selectedChartOption.value === FundingType.WANT) {
            calculations = this.props.wantsWishes?.find(({ name }) => name.includes('Wants'))?.calculations || []
        }
        if (selectedChartOption.value === FundingType.WISH) {
            calculations = this.props.wantsWishes?.find(({ name }) => name.includes('Wishes'))?.calculations || []
        }
        const output = this.mapCalculationToExcel(calculations);
        exportDataToExcel('Calculations', output, `Calculations - ${selectedChartOption.label}`)
    }

    onUpdateSavings = () => {
        this.props.onSelectedKeyChanged('savingsGoals')
    }

    render() {
        const { investmentPortfolioToday, needsInTimeChart, wantsInTimeChart, wishesInTimeChart,
                needsWentNegative, needsPortfolio, wantsPortfolio, wishesPortfolio, onComponentSize,
                needsPortfolioModel, calculatingNeeds, wantsPortfolioModel, wishesPortfolioModel,
                portfolioModels, financialPlanNeedsModels, financialPlanSource, clientId, montecarloResults, } = this.props
        const { selectedChartOption } = this.state

        const { needs: montecarloNeeds, wants: montecarloWants, wishes: montecarloWishes } = montecarloResults || {}

        const needsPortfolioExceedsInvestmentPortfolio = (montecarloNeeds?.missing_capitol ?? 0) > 0
        const wishesPortfolioExceedsInvestmentPortfolio = (montecarloWishes?.missing_capitol ?? 0) > 0
        const wantsPortfolioExceedsInvestmentPortfolio = (montecarloWants?.missing_capitol ?? 0) > 0
        const showExceedsInvestmentPortfolioWarning = !calculatingNeeds && (needsPortfolioExceedsInvestmentPortfolio || wishesPortfolioExceedsInvestmentPortfolio || wantsPortfolioExceedsInvestmentPortfolio)
        
        const needsPortfolioDisplay = needsPortfolio
        const wantsPortfolioDisplay = needsPortfolioExceedsInvestmentPortfolio && wantsPortfolioExceedsInvestmentPortfolio ? '-' : wantsPortfolio
        const wishesPortfolioDisplay = wantsPortfolioExceedsInvestmentPortfolio && wishesPortfolioExceedsInvestmentPortfolio? '-' : wishesPortfolio

        const needsSuccessRate = !calculatingNeeds && montecarloResults ? formatNumberAsPercentage(montecarloNeeds?.success_rate ?? 0) : '--'
        const wantsSuccessRate = !calculatingNeeds && !needsPortfolioExceedsInvestmentPortfolio &&  montecarloResults ? formatNumberAsPercentage(montecarloWants?.success_rate ?? 0) : '--'
        const wishesSuccessRate = !calculatingNeeds && !wantsPortfolioExceedsInvestmentPortfolio &&  montecarloResults ? formatNumberAsPercentage(montecarloWishes?.success_rate ?? 0) : '--'

        const isMoneyGuide = financialPlanSource === 'money_guide'
        
        let inTimeChart = undefined
        if (selectedChartOption?.value === FundingType.NEED) {
            inTimeChart = needsInTimeChart
        }
        if (selectedChartOption?.value === FundingType.WANT) {
            inTimeChart = wantsInTimeChart
        }
        if (selectedChartOption?.value === FundingType.WISH) {
            inTimeChart = wishesInTimeChart
        }

        return (
            <Box sx={{ width: '132.5rem', display: 'flex', flexDirection: 'row' }}>
                <Card sx={{ width: '60rem' }} title={'ALM Risk'} hideHeader hideFooter={this.props.hideFooter} onComponentSize={onComponentSize} showAddProfileHistory={false}>
                    <Box sx={{ minHeight: '40rem'}}>
                        <Box>
                            <SummaryItem label={'Investment Portfolio'} value={investmentPortfolioToday} />
                            <TotalDivider />
                            <SummaryItem
                                label={
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Text type={'form_header'} sx={{ width: '16rem' }}>Needs Portfolio</Text>
                                        <Select sx={{ width: '25rem' }} options={financialPlanNeedsModels} value={needsPortfolioModel} onChange={this.setSelectedNeedsPortfolioModel} />
                                    </Box>
                                }
                                value={needsPortfolioDisplay}
                            />
                            <SummaryItem
                                label={
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Text type={'form_header'} sx={{ width: '16rem' }}>Wants Portfolio</Text>
                                        <Select sx={{ width: '25rem' }} options={portfolioModels} value={wantsPortfolioModel} onChange={this.setSelectedWantsPortfolioModel} />
                                    </Box>
                                }
                                value={wantsPortfolioDisplay}
                            />
                            <SummaryItem
                                label={
                                    <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                        <Text type={'form_header'} sx={{ width: '16rem' }}>Wishes Portfolio</Text>
                                        <Select sx={{ width: '25rem' }} options={portfolioModels} value={wishesPortfolioModel} onChange={this.setSelectedWishesPortfolioModel} />
                                    </Box>
                                }
                                value={wishesPortfolioDisplay}
                            />
                        </Box>
                        <Box sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-end'}}>
                                {isMoneyGuide && <Box sx={{ marginTop: '1rem'}}><Text>Source: MoneyGuide</Text></Box> }
                                {!isMoneyGuide &&<Button title={<>Update Savings<br />& Goals</>} onClick={this.onUpdateSavings} sx={{ minWidth: 'unset', minHeight: 'unset', width: '20rem', height: '4.5rem' }} /> }
                        </Box>
                    
                        <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                            {showExceedsInvestmentPortfolioWarning &&
                                <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                                    <Text sx={{ color: '#b20000', fontWeight: 'bold', textAlign: 'center', marginTop: '1.5rem' }}>Warning: </Text>
                                    {needsPortfolioExceedsInvestmentPortfolio && <Text sx={{ color: '#b20000', fontWeight: 'bold', textAlign: 'center', marginTop: '0.25rem' }}>Need an additional {montecarloNeeds?.missing_capitol.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} to meet Needs</Text>}
                                    {!needsPortfolioExceedsInvestmentPortfolio && wantsPortfolioExceedsInvestmentPortfolio && <Text sx={{ color: '#b20000', fontWeight: 'bold', textAlign: 'center', marginTop: '0.25rem' }}>Need an additional {montecarloWants?.missing_capitol.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} to meet Wants</Text>}
                                    {!needsPortfolioExceedsInvestmentPortfolio && !wantsPortfolioExceedsInvestmentPortfolio && wishesPortfolioExceedsInvestmentPortfolio && <Text sx={{ color: '#b20000', fontWeight: 'bold', textAlign: 'center', marginTop: '0.25rem' }}>Need an additional {montecarloWishes?.missing_capitol.toLocaleString('en-US', { style: 'currency', currency: 'USD' })} to meet Wishes</Text>}
                                    
                                </Box>
                            }
                        </Box>
                    </Box>
                </Card>
                <Box sx={{ display: 'flex', flexDirection: 'column', mt: '1rem', marginLeft: '6rem',  }}>
                        <Text 
                            type='form_header' 
                            sx={{
                                display: 'flex',
                                borderRadius: 0,
                                height: '4rem',
                                padding: '2.5rem',
                                alignItems: 'center',
                                justifyContent: 'center',
                                textAlign: 'center',
                                width: '14rem'
                            }}>
                                Success Rate
                        </Text>
                    <Box sx= {{ display: 'flex', flexDirection: 'column', mt: '1.85rem' }}>
                        <Text sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 0,
                            height: '4rem',
                            padding: '2.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '14rem',
                            mt: '1.2rem',
                        }}>
                            {calculatingNeeds ? <ClipLoader size={20} /> : needsSuccessRate}
                        </Text>
                        <Text sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 0,
                            height: '4rem',
                            padding: '2.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '14rem',
                            mt: '1.25rem',
                        }}>
                            {calculatingNeeds ? <ClipLoader size={20} /> : wantsSuccessRate}
                        </Text>
                        <Text sx={{
                            display: 'flex',
                            border: (theme) => `1px solid ${theme.palette.primary.main}`,
                            borderRadius: 0,
                            height: '4rem',
                            padding: '2.5rem',
                            alignItems: 'center',
                            justifyContent: 'center',
                            width: '14rem',
                            mt: '1.25rem',
                        }}>
                            {calculatingNeeds ? <ClipLoader size={20} /> : wishesSuccessRate}
                        </Text>
                    </Box>

                </Box>
                
                <Box sx={{ display: 'flex', flexDirection: 'column', marginLeft: '5rem',  }}>
                        <Select sx={{ width: '45rem', marginLeft: '1rem' }} options={CHART_OPTIONS} value={selectedChartOption} onChange={this.setSelectedChartOption} />
                        {(calculatingNeeds)&&
                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: '5rem' }}>
                                <ClipLoader />
                            </Box>
                        }
                        {inTimeChart && !calculatingNeeds &&
                            <Box sx={{ display: 'flex', flexDirection: 'column'}}>
                                <Chart id={'needsInTimeChart'} sx={{ width: '45rem', backgroundColor: '#0000', zIndex: 0 }} chartOptions={inTimeChart} />
                            </Box>
                        }
                        {!inTimeChart && !calculatingNeeds &&
                            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                <Text sx={{ fontWeight: 'bold' }}>No Data Available</Text>
                            </Box>
                        }
                </Box>
            </Box>
        )
    }

}

const mapStateToProps = ({ standardLivingRisk, portfolioDesigner, session }: ReduxState) => {
    const { investment_portfolio_today: investmentPortfolioToday, standardLivingRisk: slr, calculatingNeeds, 
            needsPortfolio, wantsPortfolio, wishesPortfolio, needsWentNegative, needsInTimeChart, wantsInTimeChart,
            wishesInTimeChart,financial_plan_source, needs_immunization_period, needs_model_id, wants_model_id,
            wishes_model_id, years_in_financial_goal: yearsInFinancialGoal, years_to_financial_goal: yearsToFinancialGoal,
            bands, wantsWishes, montecarloResults } = standardLivingRisk
    const { portfolioModels, financial_plan_needsModels: financialPlanNeedsModels } = portfolioDesigner;
    const { selectedKey } = session
            
    
    return {
        investmentPortfolioToday: financial_plan_source === 'money_guide' ? FIXED_MONEY_GUIDE_INV_PORT_TODAY : investmentPortfolioToday,
        financialPlanSource: financial_plan_source,
        slr,
        calculatingNeeds,
        needsPortfolio,
        wantsPortfolio,
        wishesPortfolio,
        needsInTimeChart,
        wantsInTimeChart,
        wishesInTimeChart,
        needsWentNegative,
        portfolioModels,
        financialPlanNeedsModels,
        yearsInFinancialGoal,
        yearsToFinancialGoal,
        montecarloResults,
        bands,
        wantsWishes,
        selectedKey,
        needsImmunizationPeriod: needs_immunization_period,
        needsPortfolioModel: financialPlanNeedsModels?.find(({ value }) => value === needs_model_id) || null,
        wantsPortfolioModel: portfolioModels.find(({ value }) => value === wants_model_id) || null,
        wishesPortfolioModel: portfolioModels.find(({ value }) => value === wishes_model_id) || null,

    }
}

export default withClientParameter(connect(mapStateToProps, { onSelectedKeyChanged, calculateNeedsPortfolioInTime, needsPortfolioModelUpdate, needsImmunizationPeriodUpdate, wantsPortfolioModelUpdate, wishesPortfolioModelUpdate, addError, loadModelOptions, loadConfigurationOptions })(SLR as any))